import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";
import HLoginPage from '@/pages/HLoginPage.vue';

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Anfragen = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Anfragen.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Typography = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const AnfrageDetails = () =>
  import(/* webpackChunkName: "details" */ "@/pages/AnfrageDetails.vue");

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: HLoginPage
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    meta: { requiresAuth: true }, // Schutz durch Authentifizierung
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/anfragen",
        name: "anfragen",
        component: Anfragen,
      },
      {
        path: "anfragen/:eventId", // Route für AnfrageDetails
        name: "anfrageDetails",
        component: AnfrageDetails,
        props: true, // Erlaubt den Zugriff auf die Event-ID als Prop
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications,
      },
      {
        path: "icons",
        name: "icons",
        component: Icons,
      },
      {
        path: "typography",
        name: "typography",
        component: Typography,
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList,
      },
    ],
  },
  { path: "*", component: NotFound },
];

export default routes;

