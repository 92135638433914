/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: {
      scope: process.env.BASE_URL,
    },
    ready() {
      console.log(
        "App wird vom Service Worker aus dem Cache bereitgestellt.\n" +
          "Weitere Details finden Sie unter https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service Worker wurde registriert.");
    },
    cached() {
      console.log("Inhalte wurden für die Offline-Nutzung zwischengespeichert.");
    },
    updatefound() {
      console.log("Neue Inhalte werden heruntergeladen.");
    },
    updated(registration) {
      console.log("Neue Inhalte sind verfügbar. Bitte aktualisieren Sie die Seite manuell.");
      // Hier implementieren wir eine Benachrichtigung für den Benutzer
      showUpdateNotification();
    },
    offline() {
      console.log(
        "Keine Internetverbindung gefunden. Die App läuft im Offline-Modus."
      );
    },
    error(error) {
      console.error("Fehler bei der Registrierung des Service Workers:", error);
    },
  });
}

function showUpdateNotification() {
  // Überprüfen, ob der Browser Benachrichtigungen unterstützt
  if (!("Notification" in window)) {
    console.log("Dieser Browser unterstützt keine Desktop-Benachrichtigungen");
    return;
  }

  // Überprüfen, ob Benachrichtigungen erlaubt sind
  if (Notification.permission === "granted") {
    // Benachrichtigung erstellen
    var notification = new Notification("Update verfügbar", {
      body: "Es sind neue Inhalte verfügbar. Bitte aktualisieren Sie die Seite.",
      icon: "/path/to/icon.png" // Ersetzen Sie dies durch den Pfad zu Ihrem App-Icon
    });

    notification.onclick = function() {
      window.location.reload();
    };
  } else if (Notification.permission !== "denied") {
    // Erlaubnis anfordern
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        showUpdateNotification();
      }
    });
  }
}
