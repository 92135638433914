import VueRouter from "vue-router";
import routes from "./routes";
import store from '@/store/vertexstoreindex';  // Korrekt

let authChecked = false;

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Hier fügen wir den aktualisierten Router Guard hinzu
router.beforeEach(async (to, from, next) => {
  console.log('Navigating to:', to.path, 'Auth status:', store.getters.isAuthenticated);

  if (!store.state.authChecked) {
    await store.dispatch('checkAuth');
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ name: 'LoginPage' });
      return;
    }
  }

  if (to.path === '/login' && store.getters.isAuthenticated) {
    next({ name: 'dashboard' });
  } else {
    next();
  }
});

export default router;