/*
 =========================================================
 * Vue Black Dashboard - v1.1.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2024 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
 import Vue from "vue";
 import VueRouter from "vue-router";
 import RouterPrefetch from "vue-router-prefetch";
 import App from "./App";
 import router from "./router/index";
 import store from './store/vertexstoreindex'; // Import des Vuex Stores
 import BlackDashboard from "./plugins/blackDashboard";
 import i18n from "./i18n";
 import "./registerServiceWorker";
 import axios from 'axios';
 
 Vue.use(BlackDashboard);
 Vue.use(VueRouter);
 Vue.use(RouterPrefetch);
 
 // Setze withCredentials global für alle Axios-Anfragen
 axios.defaults.withCredentials = true;
 
//
 
 new Vue({
   router,
   store, // Hinzufügen des Stores zur Vue-Instanz
   i18n,
   created() {
    // Überprüfe den Authentifizierungsstatus beim Laden der Anwendung
    this.$store.dispatch('checkAuth');
  },
   render: (h) => h(App),
 }).$mount("#app");
