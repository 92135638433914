import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
//meilenstein
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    events: [],
    eventDetails: {}, // Initialisiert eventDetails
    isAuthenticated: false,
    authChecked: false,
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_EVENT_DETAILS(state, event) {
      Vue.set(state.eventDetails, event._id, event);
    },
    UPDATE_EVENT_STATUS(state, { eventId, status }) {
      const eventIndex = state.events.findIndex(event => event._id === eventId);
      if (eventIndex !== -1) {
        Vue.set(state.events, eventIndex, { ...state.events[eventIndex], status });
      }
    },
    UPDATE_EVENT_NOTES(state, { eventId, notes }) {
      const index = state.events.findIndex(event => event._id === eventId);
      if (index !== -1) {
        Vue.set(state.events[index], 'notes', notes);
      }
    },
    UPDATE_EVENT_KVH_ZUSATZVEREINBARUNG(state, { eventId, kvhZusatzVereinbarung }) {
      const eventIndex = state.events.findIndex(event => event._id === eventId);
      if (eventIndex !== -1) {
        Vue.set(state.events[eventIndex], 'kvh_zusatz_vereinbarung', kvhZusatzVereinbarung);
      }
      if (state.eventDetails && state.eventDetails[eventId]) {
        Vue.set(state.eventDetails[eventId], 'kvh_zusatz_vereinbarung', kvhZusatzVereinbarung);
      }
    },

    UPDATE_EVENT_AUS_ZUSATZVEREINBARUNG(state, { eventId, ausZusatzVereinbarung }) {
      const eventIndex = state.events.findIndex(event => event._id === eventId);
      if (eventIndex !== -1) {
        Vue.set(state.events[eventIndex], 'aus_zusatz_vereinbarung', ausZusatzVereinbarung);
      }
      if (state.eventDetails && state.eventDetails[eventId]) {
        Vue.set(state.eventDetails[eventId], 'aus_zusatz_vereinbarung', ausZusatzVereinbarung);
      }
    },

    UPDATE_EVENT_KVT_ZUSATZVEREINBARUNG(state, { eventId, kvtZusatzVereinbarung }) {
      const eventIndex = state.events.findIndex(event => event._id === eventId);
      if (eventIndex !== -1) {
        Vue.set(state.events[eventIndex], 'kvt_zusatz_vereinbarung', kvtZusatzVereinbarung);
      }
      if (state.eventDetails && state.eventDetails[eventId]) {
        Vue.set(state.eventDetails[eventId], 'kvt_zusatz_vereinbarung', kvtZusatzVereinbarung);
      }
    },

    UPDATE_EVENT_ZEL_ZUSATZVEREINBARUNG(state, { eventId, zelZusatzVereinbarung }) {
      const eventIndex = state.events.findIndex(event => event._id === eventId);
      if (eventIndex !== -1) {
        Vue.set(state.events[eventIndex], 'zel_zusatz_vereinbarung', zelZusatzVereinbarung);
      }
      if (state.eventDetails && state.eventDetails[eventId]) {
        Vue.set(state.eventDetails[eventId], 'zel_zusatz_vereinbarung', zelZusatzVereinbarung);
      }
    },

    UPDATE_EVENT_DETAILS(state, updatedEvent) {
      const eventIndex = state.events.findIndex(event => event._id === updatedEvent._id);
      if (eventIndex !== -1 && updatedEvent) {
        Vue.set(state.events, eventIndex, { ...state.events[eventIndex], ...updatedEvent });
      } else {
        console.error('UPDATE_EVENT_DETAILS: updatedEvent ist undefiniert oder Ereignis nicht in state.events gefunden');
      }
      if (state.eventDetails && state.eventDetails[updatedEvent._id]) {
        Vue.set(state.eventDetails, updatedEvent._id, { ...state.eventDetails[updatedEvent._id], ...updatedEvent });
      } else {
        console.warn('UPDATE_EVENT_DETAILS: Ereignis nicht in state.eventDetails gefunden');
      }
    },
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_AUTH_CHECKED(state, value) {
      state.authChecked = value;
    },
  },
  actions: {
    async fetchEventList({ commit }) {
      try {
        const response = await axios.get('https://eventbroker-backend.vercel.app/api/events/list', { withCredentials: true });
        commit('SET_EVENTS', response.data);
        console.log('Fetched event list:', JSON.stringify(response.data, null, 2));
      } catch (error) {
        console.error('Fehler beim Abrufen der Event-Liste:', error);
      }
    },
    async fetchEventDetails({ commit }, eventId) {
      try {
        const response = await axios.get(`https://eventbroker-backend.vercel.app/api/events/${eventId}`, { withCredentials: true });
        if (response.data) {
          commit('SET_EVENT_DETAILS', response.data);
          console.log('Fetched event details:', JSON.stringify(response.data, null, 2));
        } else {
          console.error('Fehler: Keine Daten vom Server erhalten');
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Event-Details:', error);
      }
    },
    async updateEventStatus({ commit }, { eventId, status }) {
      try {
        await axios.patch(`https://eventbroker-backend.vercel.app/api/events/${eventId}/status`, { status }, { withCredentials: true });
        commit('UPDATE_EVENT_STATUS', { eventId, status });
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Event-Status:', error);
      }
    },
    async updateEventDetails({ commit }, eventDetails) {
      try {
        const response = await axios.put(`https://eventbroker-backend.vercel.app/api/events/${eventDetails._id}`, eventDetails, { withCredentials: true });
        commit('UPDATE_EVENT_DETAILS', response.data);
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Event-Details:', error);
      }
    },
    async createHaftpflichtPDF({ dispatch, commit }, eventDetails) {
      try {
        const response = await axios.post('https://eventbroker-backend.vercel.app/api/create-haftpflicht-pdf', eventDetails, { withCredentials: true });
        console.log('PDF erstellt:', response.data.path);
        commit('UPDATE_EVENT_DETAILS', { ...eventDetails, haftpflichtPdfPath: response.data.path });
        return response;
      } catch (error) {
        console.error('Fehler beim Erstellen des PDF:', error);
      }
    },
    async createAusfallPDF({ dispatch, commit }, eventDetails) {
      try {
        const response = await axios.post('https://eventbroker-backend.vercel.app/api/create-ausfall-pdf', eventDetails, { withCredentials: true });
        console.log('PDF erstellt:', response.data.path);
        commit('UPDATE_EVENT_DETAILS', { ...eventDetails, ausfallPdfPath: response.data.path });
        return response;
      } catch (error) {
        console.error('Fehler beim Erstellen des Ausfall-PDF:', error);
      }
    },
    async createTechnikPDF({ dispatch, commit }, eventDetails) {
      console.log('createTechnikPDF action called');
      try {
        const response = await axios.post('https://eventbroker-backend.vercel.app/api/create-technik-pdf', eventDetails, { withCredentials: true });
        console.log('Technik PDF erstellt:', response.data.path);
        commit('UPDATE_EVENT_DETAILS', { ...eventDetails, technikPdfPath: response.data.path });
        return response;
      } catch (error) {
        console.error('Fehler beim Erstellen des Technik-PDF:', error);
      }
    },
    async createZeltPDF({ dispatch, commit }, eventDetails) {
      console.log('createZeltPDF action called');
      try {
        const response = await axios.post('https://eventbroker-backend.vercel.app/api/create-zelt-pdf', eventDetails, { withCredentials: true });
        console.log('Zelt PDF erstellt:', response.data.path);
        commit('UPDATE_EVENT_DETAILS', { ...eventDetails, zeltPdfPath: response.data.path });
        return response;
      } catch (error) {
        console.error('Fehler beim Erstellen des Zelt-PDF:', error);
      }
    },
    async createInvoice({ commit }, { eventId, insuranceType }) {
      console.log("createInvoice action called", eventId, insuranceType);
      try {
        const response = await axios.post('https://eventbroker-backend.vercel.app/api/create-invoice', {
          eventId,
          insuranceType
        }, { withCredentials: true });
        console.log('Rechnung erstellt:', response.data);
        const updatedEventDetails = {
          _id: eventId,
          [`${insuranceType}InvoicePath`]: response.data.path
        };
        commit('UPDATE_EVENT_DETAILS', updatedEventDetails);
        console.log('Event Details updated in store:', updatedEventDetails);
        return response.data;
      } catch (error) {
        console.error('Fehler bei der Rechnungserstellung:', error);
        throw error;
      }
    },
    async triggerDownload({ state }, { eventId, insuranceType, documentType, filename }) {
      try {
        console.log('Download gestartet', { eventId, insuranceType, documentType, filename });
        const url = 'https://eventbroker-backend.vercel.app/api/downloadpdf';
        console.log(`URL für den Download: ${url}`);
        const response = await axios({
          method: 'post',
          url: url,
          data: {
            eventId,
            insuranceType,
            documentType
          },
          responseType: 'blob',
          withCredentials: true
        });
        if (response.status === 200) {
          console.log('Erfolgreiche Antwort erhalten, starte Download');
          const fileURL = window.URL.createObjectURL(response.data);
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename || `${insuranceType}_${documentType}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          console.log('Download erfolgreich abgeschlossen');
        } else {
          console.error('Fehler beim Auslösen des Downloads:', response);
        }
      } catch (error) {
        console.error('Fehler beim Download:', error);
      }
    },
    async login({ commit }, userData) {
      console.log("Login action aufgerufen", userData);
      try {
        const response = await axios.post('https://eventbroker-backend.vercel.app/login', userData, { withCredentials: true });
        console.log("Antwort vom Server:", response);
        if (response.data && (response.data.success || response.data.message === "Erfolgreich angemeldet")) {
          commit('SET_AUTHENTICATED', true);
          return { success: true, message: "Erfolgreich angemeldet" };
        } else {
          console.error("Login fehlgeschlagen:", response.data.message || 'Unbekannter Fehler');
          return { success: false, message: response.data.message || 'Login fehlgeschlagen' };
        }
      } catch (error) {
        console.error('Fehler beim Login:', error.response?.data || error.message);
        return { success: false, message: error.response?.data?.message || 'Ein Fehler ist aufgetreten' };
      }
    },
    async logout({ commit }) {
      try {
        await axios.post('https://eventbroker-backend.vercel.app/logout', {}, { withCredentials: true });
        commit('SET_AUTHENTICATED', false);
      } catch (error) {
        console.error('Fehler beim Logout:', error);
      }
    },
    async checkAuth({ commit, state }) {
      console.log("Starte Authentifizierungsprüfung");
      if (state.authChecked) {
        console.log('Auth bereits geprüft, Ergebnis:', state.isAuthenticated);
        return state.isAuthenticated;
      }
      
      try {
        console.log('Sende Auth-Prüfanfrage');
        const response = await axios.get('https://eventbroker-backend.vercel.app/api/check-auth', { withCredentials: true });
        console.log('Auth-Prüfantwort:', response.data);
        const isAuthenticated = response.data.authenticated === true;
        commit('SET_AUTHENTICATED', isAuthenticated);
        commit('SET_AUTH_CHECKED', true);
        console.log('Auth-Prüfung abgeschlossen, authentifiziert:', isAuthenticated);
        return isAuthenticated;
      } catch (error) {
        console.error('Fehler bei der Auth-Prüfung:', error);
        commit('SET_AUTHENTICATED', false);
        commit('SET_AUTH_CHECKED', true);
        return false;
      }
    },
  },
  getters: {
    allEvents: state => state.events,
    getEventDetails: state => eventId => state.eventDetails ? state.eventDetails[eventId] : null,
    isAuthenticated: state => state.isAuthenticated,
  },
});