<template>
  <div class="login-container">
    <div class="login-card">
      <img
        src="https://eventversicherungen.b-cdn.net/wp-content/uploads/2024/07/heinemann-eventversicherungen-seit1980-logo-24-5-scaled.webp"
        alt="Heinemann Eventversicherungen Logo"
        class="logo"
      />
      <h2 class="text-center">Login</h2>
      <form @submit.prevent="login">
        <input
          v-model="username"
          placeholder="Benutzername"
          required
          class="base-input"
        />
        <input
          type="password"
          v-model="password"
          placeholder="Passwort"
          required
          class="base-input"
        />
        <button type="submit" class="base-button">Login</button>
      </form>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async login() {
  console.log("Login-Methode aufgerufen");
  try {
    const result = await this.$store.dispatch('login', {
      username: this.username,
      password: this.password,
    });
    if (result.success) {
  console.log("Login erfolgreich:", result.message);
  await this.$store.dispatch('checkAuth');
  setTimeout(() => {
    this.$router.push({ name: 'dashboard' }).catch(err => {
      console.error('Navigation fehlgeschlagen:', err);
      if (err.name !== 'NavigationDuplicated') {
        window.location.href = '/dashboard';
      }
    });
  }, 100);
} else {
  console.error("Login fehlgeschlagen:", result.message);
  alert(`Login fehlgeschlagen: ${result.message}`);
}
  } catch (error) {
    console.error("Fehler beim Login", error);
    alert('Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
  }
},
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-card {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.logo {
  max-width: 150px;
  margin-bottom: 20px;
}

.base-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.base-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.base-button:hover {
  background-color: #0056b3;
}

.text-center {
  margin-bottom: 30px;
}
</style>

